import React, {useEffect, useState} from 'react';
import {Card, Col, Container, FormControl} from "react-bootstrap";
import {Entry} from "./Types";
import ReactAudioPlayer from "react-audio-player";

function App() {

    const [content, setContent] = useState<Entry[]>([]);

    useEffect(() => {
        fetch("https://batlibrarycdn.garrus.de/content.json").then(response => {
            if (response.ok) {
                response.json().then(json => {
                    setContent(json as Entry[]);
                })
            }
        })
    }, [])

    return (
        <Container className={"justify-content-center text-center"}>
            <h1>Bat Library</h1>
            <FormControl placeholder={"Search"} className={"m-2"}/>
            {content.map(entry => {
                return (
                    <Card key={entry.id} as={Col} xl={4}>
                        <Card.Img variant="top" src={entry.preview_image}/>
                        <Card.Body>
                            <Card.Title>{entry.name_de}</Card.Title>
                            <Card.Subtitle style={{fontStyle:"oblique"}}>{entry.lat_name}</Card.Subtitle>
                            <Card.Text>
                                {entry.description_de}
                            </Card.Text>
                            <ReactAudioPlayer src={entry.audio_samples[0]} controls/>
                        </Card.Body>
                    </Card>
                )
            })}
        </Container>
    );
}

export default App;
